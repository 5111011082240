.form {
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  .form-field {
    display: flex;
    flex-direction: column;
    padding-bottom: $gap/2;
    &.has-checkbox {
      flex-direction: row;
      align-items: center;
      input {
        margin-right: $gap/4;
      }
    }
    &.two-columns {
      flex-direction: column;
      @include desktop {
        flex-direction: row;
      }
      .form-field-column{
        flex: .5;
        flex-direction: column;
        &:first-child {
          padding-right: 0;
          @include desktop {
            padding-right: $gap/4;
          }
        }
        &:last-child {
          padding-left: 0;
          @include desktop {
            padding-left: $gap/4;
          }
        }
      }
    }
    a.form-link {
      text-decoration: none;
      color: $color-black;
      border-bottom: 2px solid $color-white;
      &:before {
        font-family: "kinderbuch_icons";
        speak: none;
        padding-right: 5px;
        color: $color-yellow;
        content: "\e900";
      }
      &:hover, &:active {
        border-bottom: 2px solid $color-yellow;
      }
    }
    label, input:not([type='checkbox']), select, button, p {
      @include desktop {
        display: flex;
      }
      flex: 1;
      width: 100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
    }
    input {
      border: 0;
      border-bottom: 1px solid $color-black;
      background: $color-lightyellow;
      &[type=text],&[type=number],&[type=email] {
        padding-left: $gap/8;
        padding-right: $gap/8;
      }
    }
    select {
      border: 0;
      border-bottom: 1px solid $color-black;
      background: $color-lightyellow;
      border-radius: 0;
      padding-left: $gap/8;
      padding-right: $gap/8;
      padding-top: 1px;
      padding-bottom: 1px;
      -webkit-appearance: none;
      &::-ms-expand {
        display: none;
      }
    }
    .select-wrapper {
      position: relative;
      span{
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 0;
        text-align: center;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        width: $gap/3;
      }
    }
    button {
      text-align: center;
      justify-content: center;
      background: $color-yellow;
      border: 1px solid $color-black;
      border-radius: 0;
      padding: $gap/4;
      color: $color-black;
      transition: all 125ms ease-out;
      &:hover {
        background: $color-green;
        color: $color-white;
        transition: all 250ms ease-in;
      }
      &:disabled, &:disabled:hover{
        background: $color-lightgrey;
        border-color: $color-grey;
        color: $color-grey;
      }
    }
  }
}
