//color-variables
$color-green: #60ae56;
$color-red: #fb5c45;
$color-blue: #03bbee;
$color-lightblue: #b7e2fa;
$color-orange: #fc8f1f;
$color-violet: #c286be;
$color-yellow: #ffed4e;
$color-lightyellow: #fff6b6;
$color-grey: #c4bdb8;
$color-brown: #d3a594;
$color-lightgrey: #eeefee;

$color-black: #000000;
$color-white: #ffffff;
$color-transparent: transparent;

//breakpoints
$gap: 64px !default;
$tablet: 769px !default;
$desktop: 960px + $gap !default;
$widescreen: 1152px + (2 * $gap) !default;
$fullhd: 1344px + (2 * $gap) !default;
