.top-row {
  background-color: $color-white;
  border-bottom: 1px solid $color-lightgrey;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 40px;
  position: relative;
  width: 100%;
  @include until ($desktop) {
    margin-top: 0;
    padding-top: 10px;
    position: fixed;
    top: 20px;
  }
  @include desktop {
    border: none;
    margin-top: 50px;
  }
  .logo {
    max-width: 260px;
    margin-left: 15px;
    margin-right: 15px;
    @include widescreen {
      margin-left: 65px;
      margin-right: 65px;
    }
  }
  .nav-trigger {
    margin-right: 10px;
    display: block;
    color: $color-blue;
    cursor: pointer;
    @include desktop {
      display: none;
    }
  }
}
.mobile-menu {
  @include mobile {
    height: calc(100vh - 73px);
  }
  overflow-y: scroll;
  position: fixed;
  top: 73px;
  display: none;
  width: 100%;
  background-color: $color-white;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-lightgrey;
  &.is-open {
    display: block;
    @include desktop {
      display: none;
    }
  }
  ul.navbar {
    max-width: 400px;
    margin: 0 auto;
    li {
      width: 100%;
      a.navbar-item {
        display: flex;
        align-items: center;
        width: 100%;
        img.navbar-img {
          width: 50px;
          height: 50px;
        }
      }
    }
    li:first-child {
      margin-top: 10px;
    }
    li:not(:last-child) {
        border-bottom: 1px solid $color-lightgrey;
        margin-bottom: 10px;
    }
  }
}

.navbar-container {
  display: flex;
  flex: 0.25;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
  @include until($desktop) {
    display: none;
  }
  @include widescreen {
    margin-left: 35px;
    margin-right: 35px;
  }
}

.navbar-items {
  display: flex;
}

ul.navbar {
  width: 100%;
  li {
    width: 100%;
    margin-bottom: 30px;
    a.navbar-item {
      border-bottom: 5px solid $color-white;
      display: flex;
      flex-direction: row;
      max-height: 75px;
      margin: 0;
      box-sizing: border-box;
      text-decoration: none;
      color: $color-black;
      width: 100%;
      span.navbar-text {
        align-items: center;
        display: flex;
        flex: 1;
        flex-grow: 1;
        font-size: 25px;
        line-height: 35px;
        padding: 10px;
      }
      img.navbar-img {
        width: 70px;
        height: 70px;
      }
    }
  }
  li.start {
    a.navbar-item:hover, a.navbar-item:active  {
      border-bottom-color: $color-red;
    }
  }
  li.buchinfo {
    a.navbar-item:hover, a.navbar-item:active {
      border-bottom-color: $color-orange;
    }
  }
  li.bestellen {
    a.navbar-item:hover, a.navbar-item:active {
      border-bottom-color: $color-yellow;
    }
  }
  li.kontakt {
    a.navbar-item:hover, a.navbar-item:active {
      border-bottom-color: $color-green;
    }
  }
  li.meinung {
    a.navbar-item:hover, a.navbar-item:active {
      border-bottom-color: $color-blue;
    }
  }
  li.macher {
    a.navbar-item:hover, a.navbar-item:active {
      border-bottom-color: $color-violet;
    }
  }
  li.footer {
    a.footer-item {
      border-bottom: 5px solid $color-white;
      color: $color-black;
      margin-top: 25px;
      margin-left: 10px;
      margin-right: 10px;
      &:first-child {
        margin-left: 0;
      }
      position: relative;
      display: inline-block;
      text-decoration: none;
      line-height: 1;
      &:not(:first-child) {
        &:before {
          content: "";
          height: 17px;
          width: 2px;
          background: black;
          left: -10px;
          top: 0;
          position: absolute;
        }
      }
      &:last-child {
        margin-right: 0;
        &:before {
          border-right: none;
        }
      }
      span.footer-text {
        font-size: 17px;
      }
    }
    a.footer-item:hover, a.footer-item:active {
      border-bottom-color: $color-grey;
    }
  }
}
