$optInFontFamily: sans-serif !default;
$optInFontScript: $optInFontFamily !default;
$optInFontSize: 16px !default;
$optInAccentFG: white !default; // accept-all button foreground
$optInAccentBG: dodgerblue !default; // accept-all button background, "active slider" background, text-link color
$optInMainBG: white !default; // dialog panel background color
$optInOutlineFG: #555555 !default; // border- and text color of the config button
$optInSliderBG: #CCCCCC !default; // "inactive slider" background
$optInChoicePadding: 0.75em !default; // padding for the choices
$optInSliderThingy: 15px !default; // circle shape of the slider (width, height)
$optInSliderPadding: 3px !default; // padding of slider
$optInStageBG: rgba(black, 0.8) !default; // background of the layer that lies over the document.body
$optInStagePadding: 3rem 1rem 1rem !default; // padding of the layer that lies over the document.body
$optInZIndex: 1000 !default; // z-index of the whole layer

.optIn {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  font-family: $optInFontFamily;
  font-size: $optInFontSize;
  background: $optInStageBG;
  padding: $optInStagePadding;
  overflow-y: auto;
  z-index: $optInZIndex;
  .dialog {
    position: relative;
    display: block;
    width: 100%;
    max-width: 500px;
    color: #222222;
    background: $optInMainBG;
    padding: 1em;
    margin: 0 auto;
    border-radius: 0.75em;
    header {
      position: relative;
      display: block;
      font-family: $optInFontScript;
      font-size: 1.5em;
      padding: 0 0 0.25em;
      margin: 0 0 0.66em;
      border-bottom: 1px solid #dddddd;
    }
    .content {
      p {
        font-size: 1em;
        line-height: 1.4;
        margin: 0;
        a:link,
        a:visited,
        a:active,
        a:hover {
          color: inherit;
          text-decoration: none;
          box-shadow: inset 0 -2px 0 $optInAccentBG;
        }
        a:focus {
          outline-color: $optInAccentBG;
        }

      }
    }
    footer {
      position: relative;
      display: block;
      margin: 0;
      padding: 1em 0 0;
    }
    .buttons {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5em -1em;
      button.btn {
        position: relative;
        display: block;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        font-family: inherit;
        font-size: inherit;
        flex: 1;
        padding: 0.5em;
        margin: 0 0.5em 1em;
        border: none;
        color: $optInAccentFG;
        background: $optInAccentBG;
        border: 2px solid $optInAccentBG;
        border-radius: 0.33em;
        &.outline {
          color: $optInOutlineFG;
          background: $optInMainBG;
          border: 2px solid $optInOutlineFG;
        }
        &:hover, &:focus {
          color: $optInAccentBG;
          background: $optInMainBG;
          border: 2px solid $optInAccentBG;
        }
        &:focus {
          outline-color: $optInAccentBG;
        }
      }
    }
    .choices {
      position: relative;
      display: block;
      margin: 0.75em 0 0;
      padding: 0 0 $optInChoicePadding;
      background: darken($optInMainBG, 4);
      border-radius: 0.75em;
      .choice {
        padding: 0.5em 0;
        .switch {
          padding: $optInChoicePadding;
          &:focus-within {
            outline: 2px solid $optInAccentBG;
          }
        }
        p {
          font-size: 0.9em;
          padding: 0 0.9em;
          margin: 0;
        }
      }
    }

    .switch {
      position: relative;
      display: flex;
      align-items: center;
      span.labelText {
        font-family: $optInFontScript;
        font-size: 1.15em;
      }
      cursor: pointer;
      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      $sliderHeight: calc(#{$optInSliderThingy} + #{$optInSliderPadding * 2});
      $sliderWidth: calc(#{$sliderHeight} * 2);

      .slider {
        position: relative;
        display: block;
        margin-right: 0.5em;
        width: $sliderWidth;
        height: $sliderHeight;
        flex: none;
        background-color: $optInSliderBG;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: $optInSliderThingy;
          width: $optInSliderThingy;
          left: $optInSliderPadding;
          bottom: $optInSliderPadding;
          background-color: $optInMainBG;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
        &.round {
          border-radius: $sliderHeight;
          &::before {
            border-radius: 50%;
          }
        }
      }

      input[disabled] + .slider {
        opacity: 0.6;
      }
      input:checked + .slider {
        background-color: $optInAccentBG;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px $optInAccentBG;
      }
      input:checked + .slider:before {
        -webkit-transform: translateX($sliderHeight);
        -ms-transform: translateX($sliderHeight);
        transform: translateX($sliderHeight);
      }
    }
  }
}
.optIn,
.optIn * {
  box-sizing: border-box;
}

button.mrm-optin-btn {
  position: relative;
  display: inline-block;
  color: inherit;
  background: white;
  appearance: none;
  padding: 0.66em 1em;
  margin: 0.5em 0 1em;
  border-radius: 6px;
  border: 2px solid $optInAccentBG;
}
button.mrm-optin-btn span {
  font-family: $optInFontFamily;
  font-size: 1.2rem;
}
button.mrm-optin-btn:hover, button.mrm-optin-btn:focus {
  color: inherit;
  background: $optInAccentBG;
}