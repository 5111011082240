@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device - 1px) {
    @content;
  }
}

//custom mixins for matomo-iframe-optout
@mixin between-small {
  @media screen and (min-width: 379px) and (max-width: 451px) {
    @content;
  }
}

@mixin between-medium {
  @media screen and (min-width: 452px) and (max-width: 532px) {
    @content;
  }
}

@mixin between-large {
  @media screen and (min-width: 533px) and (max-width: $tablet - 1px) {
    @content;
  }
}
//custom mixins end

@mixin mobile {
  @media screen and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet), print {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin touch {
  @media screen and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin until-widescreen {
  @media screen and (max-width: $widescreen - 1px) {
    @content;
  }
}

@mixin widescreen {
  @media screen and (min-width: $widescreen) {
    @content;
  }
}

@mixin widescreen-only {
  @media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin until-fullhd {
  @media screen and (max-width: $fullhd - 1px) {
    @content;
  }
}

@mixin fullhd {
  @media screen and (min-width: $fullhd) {
    @content;
  }
}
