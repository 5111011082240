@charset "UTF-8";
@import "minireset";
@import "fonts";
@import "bpmixins";
@import "variables";

@import "cookiebanner";
@import "hamburger";
@import "navbar";

@import "form";

$defaultFont: "Open Sans", sans-serif;
$scriptFont: "Atma", cursive;

html {
  font-family: $defaultFont;
  font-size: 16px;
}
body {
  @include until($desktop) {
    padding-top: 93px;
  }
  &.mobile-open {
    overflow-y: hidden;
  }
  &:before,
  &:after {
    background-position: top left;
    background-size: cover;
    content: "";
    height: 20px;
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    @include desktop {
      height: 30px;
    }
  }
  &:before {
    background-image: url(/public/gfx/HG_oben_60x6000.jpg);
    top: 0;
  }
  &:after {
    background-image: url(/public/gfx/HG_unten_60x6000.jpg);
    bottom: 0;
  }
}

.site-wrapper {
  display: block;
  max-width: 1600px;
  margin: 0 auto;
}

.content-wrapper {
  display: flex;
  flex: 1;
  margin: 0;
  @include widescreen {
    margin: 30px;
  }
}

.main-container {
  flex: 1;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
  width: 100%;
  @include desktop {
    flex: 0.75;
  }
  @include widescreen {
    padding-left: 35px;
    padding-right: 35px;
  }
}

img {
  height: auto;
  max-width: 100%;
}

h1,
h2,
span {
  font-family: $scriptFont;
  font-weight: 400;
}

h1 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 20px;
  @include tablet {
    font-size: 50px;
    line-height: 60px;
    margin-bottom: 25px;
  }
  @include desktop {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 30px;
  }
}

h2 {
  font-size: 25px;
  line-height: 35px;
  margin-top: 30px;
  margin-bottom: 10px;
  hyphens: manual;
  @include tablet {
    font-size: 30px;
    line-height: 40px;
  }
  @include desktop {
    font-size: 35px;
    line-height: 45px;
  }
}

p,
.running-text,
.link,
.address {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  @include tablet {
    font-size: 20px;
  }
  @include desktop {
    font-size: 22px;
  }
}

.running-text,
.link,
.address {
  margin-bottom: 20px;
}

.text-img-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  @include until($desktop) {
    flex-direction: column;
  }
}

.intro-text {
  flex: 0.5;
}

.img-container {
  flex: 0.5;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
  max-width: 100%;
  padding-left: 20px;
  @include until($desktop) {
    max-width: 350px;
    align-self: center;
    padding-left: 0;
    margin-top: 25px;
  }
}

.cover-image {
  max-width: 350px;
  align-self: center;
  @include until($desktop) {
    max-width: 300px;
  }
}

.running-text {
  margin-top: 0;
  margin-right: 0;
  @include tablet {
    margin-right: 15px;
  }
  @include desktop {
    margin-right: 30px;
  }

  a {
    text-decoration: none;
    color: $color-black;
    &:before {
      font-family: "kinderbuch_icons";
      speak: none;
      padding-right: 5px;
      color: $color-orange;
      content: "\e900";
    }
    &:hover,
    &:active {
      border-bottom: 2px solid $color-orange;
    }
    &.email {
      &:before {
        color: $color-green;
        content: "\e159";
      }
      &:hover,
      &:active {
        border-bottom: 2px solid $color-green;
      }
    }
  }

  em {
    background-color: $color-red;
    color: $color-white;
    font-style: normal;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.link {
  margin-top: 15px;
  margin-bottom: 0;
  a {
    text-decoration: none;
    color: $color-black;
    &:before {
      color: $color-blue;
      content: "\e900";
      font-family: "kinderbuch_icons";
      speak: none;
      padding-right: 5px;
    }
    &:hover,
    &:active {
      border-bottom: 2px solid $color-blue;
    }
  }
}

.sub-text {
  font-size: 15px;
  margin-top: 10px;
  &:not(:last-child) {
    margin-bottom: 30px;
    @include widescreen {
      margin-bottom: 35px;
    }
  }
  a {
    text-decoration: none;
    color: $color-black;
    &:before {
      color: $color-blue;
      content: "\e0b9";
      font-family: "kinderbuch_icons";
      speak: none;
      padding-right: 5px;
    }
    &:hover,
    &:active {
      border-bottom: 2px solid $color-blue;
    }
  }
}

.address {
  a {
    text-decoration: none;
    color: $color-black;
    &:before {
      color: $color-green;
      content: "\e159";
      font-family: "kinderbuch_icons";
      speak: none;
      padding-right: 5px;
    }
    &:hover,
    &:active {
      border-bottom: 2px solid $color-green;
    }
    &.webaddress {
      &:before {
        color: $color-green;
        content: "\e894";
      }
      &:hover,
      &:active {
        border-bottom: 2px solid $color-green;
      }
    }
  }
}

#matomo-iframe-optout {
  display: block;
  width: 100%;
  border: 2px solid $color-orange;
  margin-bottom: $gap/4;
  @include mobile {
    min-height: 310px;
  }
  @include between-small {
    min-height: 280px;
  }
  @include between-medium {
    min-height: 240px;
  }
  @include between-large {
    min-height: 200px;
  }
  @include tablet {
    min-height: 170px;
  }
}

// Override vars…
$optInFontFamily: $defaultFont;
$optInFontScript: $scriptFont;
$optInFontSize: 15px;
$optInAccentBG: $color-blue;
$optInStageBG: rgba(black, 0.75);
$optInStagePadding: 2rem 1rem;
// …BEFORE the import
@import "optin";
