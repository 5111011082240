.cookie-wrapper {
  position: fixed;
  bottom: 0;
  max-width: 100%;
  z-index: 100000;
  background: $color-lightblue;
  border-top: 2px solid $color-blue;
  padding: 15px;

  .cookie-container {
    display: flex;
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      align-items: center;
    }
    .cookie-text-holder {
      @include tablet {
        flex-basis: 100%;
        padding-right: 25px;
      }
      .cookie-text {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        a.cookie-link {
          text-decoration: none;
          color: $color-black;
          &:before {
            font-family: "kinderbuch_icons";
            speak: none;
            padding-right: 5px;
            color: $color-blue;
            content: "\e900";
          }
          &:hover, &:active {
            border-bottom: 2px solid $color-blue;
          }
        }
      }
    }
    .cookie-button-holder {
      width: 100%;
      @include tablet {
        width: auto;
      }
      .cookie-button {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 15px;
        display: flex;
        background: $color-white;
        border: 2px solid $color-blue;
        border-radius: 0;
        text-transform: uppercase;
        color: $color-black;
        cursor: pointer;
        justify-content: center;
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
        @include tablet {
          margin-top: 0;
          margin-left: auto;
          max-width: 170px;
        }
        padding-bottom: calc(.375em - 1px);
        padding-left: .75em;
        padding-right: .75em;
        padding-top: calc(.375em - 1px);
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}
