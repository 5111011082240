@font-face {
  font-family: 'kinderbuch_icons';
  src:  url('/public/fonts/kinderbuch_icons.eot?o3j6tc');
  src:  url('/public/fonts/kinderbuch_icons.eot?o3j6tc#iefix') format('embedded-opentype'),
    url('/public/fonts/kinderbuch_icons.ttf?o3j6tc') format('truetype'),
    url('/public/fonts/kinderbuch_icons.woff?o3j6tc') format('woff'),
    url('/public/fonts/kinderbuch_icons.svg?o3j6tc#kinderbuch_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/public/fonts/google/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/public/fonts/google/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/public/fonts/google/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/google/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/google/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/public/fonts/google/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* atma-regular - latin */
@font-face {
  font-family: 'Atma';
  font-style: normal;
  font-weight: 400;
  src: url('/public/fonts/google/atma-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/public/fonts/google/atma-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/public/fonts/google/atma-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/google/atma-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/public/fonts/google/atma-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/public/fonts/google/atma-v15-latin-regular.svg#Atma') format('svg'); /* Legacy iOS */
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kinderbuch_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fax:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e0cd";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-comment:before {
  content: "\e0b9";
}
.icon-edit:before {
  content: "\e254";
}
.icon-menu:before {
  content: "\e3c7";
}
.icon-mail:before {
  content: "\e159";
}
.icon-gavel:before {
  content: "\e90e";
}
.icon-info:before {
  content: "\e88f";
}
.icon-link:before {
  content: "\e157";
}
.icon-keyboard:before {
  content: "\e312";
}
.icon-arrow_down:before {
  content: "\e313";
}
.icon-language:before {
  content: "\e894";
}
.icon-location:before {
  content: "\e8b4";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-tigerspur:before {
  content: "\e900";
}
