@charset "UTF-8";

// Settings
// ==================================================
$hamburger-padding-x: 9px !default;
$hamburger-padding-y: 12px !default;
$hamburger-layer-width: 24px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: $color-blue !default;
$hamburger-layer-border-radius: 0px !default;
$hamburger-hover-opacity: 0.7 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: 1 !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: opacity(100%) !default;

// Hamburger
// ==================================================
.hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;
  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter; }
    @else {
      opacity: $hamburger-hover-opacity; } }
  &.is-active {
    &:hover {
      @if $hamburger-hover-use-filter == true {
        filter: $hamburger-active-hover-filter; }
      @else {
        opacity: $hamburger-active-hover-opacity; } }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $hamburger-active-layer-color; } } }

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
  overflow: hidden; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;
  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  &::before,
  &::after {
    content: "";
    display: block; }
  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1; }
  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1; } }

.hamburger--elastic {
  .hamburger-inner {
    top: $hamburger-layer-height / 2;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &::before {
      top: $hamburger-layer-height + $hamburger-layer-spacing;
      transition: opacity 0.125s 0.275s ease; }
    &::after {
      top: $hamburger-layer-height * 2 + $hamburger-layer-spacing * 2;
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); } }
  &.is-active {
    .hamburger-inner {
      $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
      transform: translate3d(0, $y-offset, 0) rotate(135deg);
      transition-delay: 0.075s;
      &::before {
        transition-delay: 0s;
        opacity: 0; }
      &::after {
        transform: translate3d(0, $y-offset * -2, 0) rotate(-270deg);
        transition-delay: 0.075s; } } } }

.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); } }
  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); } } } }
